<template>
  <v-container>
    <v-text-field
      v-model="priceData.price"
      label="Pris"
      outlined
      dense
      type="number"
      min="0"
      max="50000"
      style="width:120px"
      suffix="Kr"
      hide-details
    ></v-text-field>
    <template v-if="pricePerType == 'client'">
      <div class="text-subtitle-1 font-weight-medium pt-2 mt-4">Specialpriser per besökartyp</div>
    {{ priceData.clientTypes }} 
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="clientType in priceData.clientTypeData.clientTypes" :key="clientType.id">
              <td style="width:120px;">
                <v-checkbox
                  v-model="priceData.clientTypeData.ctpIds"
                  :label="clientType.name"
                  :value="clientType.id"
                ></v-checkbox>
              </td>
              <td>
                <v-simple-table
                  v-show="priceData.clientTypeData.ctpIds.indexOf(clientType.id) != -1"
                  class="xSimpleTable width-auto"
                >
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="price in clientType.prices" :key="price.id">
                        <td style="width:100px;">
                          {{ price.name }}
                        </td>
                        <td>
                          <v-text-field
                            v-model="price.price"
                            label="Pris"
                            outlined
                            dense
                            type="number"
                            min="0"
                            max="50000"
                            style="width:120px"
                            suffix="Kr"
                            hide-details
                          ></v-text-field>
                        </td>
                        <td
                          v-if="clientType.hasAge"
                          class="px-0"
                        >
                          Upp t.o.m
                        </td>
                        <td>
                          <v-text-field
                            v-if="clientType.hasAge"
                            v-model="price.toAge"
                            :placeholder="'' + price.toAgePlaceholder"
                            outlined
                            dense
                            type="number"
                            min="0"
                            max="100"
                            style="width:60px"
                            hide-details
                          ></v-text-field>
                        </td>
                        <td
                          v-if="clientType.hasAge"
                          class="px-0"
                        >
                          år
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </v-container>
</template>
<script>
import { generalMixin } from '../mixins/general'

export default {
  props: {
    priceData: {
      type: Object,
      required: true
    },
    pricePerType: {
      type: String,
      required: true
    },
  },
  mixins: [generalMixin],
  data: () => ({
  }),
  created() {
  }
}
</script>