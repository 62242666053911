<template>
  <v-main>
    <template v-if="viewDataIsLoaded">
      <v-app-bar
        fixed
        elevate-on-scroll
        dark
        color="primary"
      >
        <v-btn
          icon
          @click="goHome()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ event.evn_id == '' ? 'Nytt event' : 'Event: ' + event.evn_str_title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="saveEvent"
          >
            Spara
          </v-btn>
        </v-toolbar-items>
        <template v-slot:extension>
          <v-tabs
            v-model="tabMain"
            align-with-title
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.label"
              :to="'#' + tab.label"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>
       </template>
      </v-app-bar>
      <v-container>
        <v-row class="pt-16">
          <v-col class="pt-16 pb-16">
            <v-form ref="formEdit">
              <v-tabs-items v-model="tabMain">
                <!--tab: general-->
                <v-tab-item
                  value="general"
                >
                  <!--active-->
                  <v-switch
                    v-model="event.active"
                    label="Aktivt"
                  ></v-switch>
                  <!--title-->
                  <v-text-field
                    label="Titel"
                    v-model="event.evn_str_title"
                    outlined
                    dense
                    :rules="[formRules.required]"
                    validate-on-blur
                    maxlength="100"
                  ></v-text-field>
                  <!--event group-->
                  <v-select
                    label="Grupp"
                    v-model="event.evn_id_egr"
                    outlined
                    dense
                    :items="eventGroups"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                  <!--description-->
                  <v-textarea
                    v-model="event.evn_txt_description"
                    label="Beskrivning"
                    outlined
                    dense
                    auto-grow
                  ></v-textarea>
                  <!--confirmationEmail-->
                  <v-textarea
                    v-model="event.evn_txt_confirmation_email"
                    label="Meddelande i bokningsbekräfelse"
                    outlined
                    dense
                    auto-grow
                  ></v-textarea>
                  <div class="pt-8" v-if="!event.isNew">
                    <v-btn
                      text
                      color="error"
                      outlined
                      @click="confirmDelete = true"
                    >
                      Ta bort hela eventet
                    </v-btn>
                  </div>
                </v-tab-item>
                <!--tab: occasion-->
                <v-tab-item
                  value="occasion"
                >
                  <div class="text-subtitle-1 font-weight-medium py-2">Begränsa bokningar utifrån antal:</div>
                  <!--appointment limit-->
                  <v-btn-toggle
                    v-model="event.evn_enm_multiple_appointment_type"
                    class="xDenseToggle mb-4"
                    color="blue darken-4"
                    mandatory
                  >
                    <v-btn
                      v-for="multipleAppointmentType in multipleAppointmentTypes"
                      :key="multipleAppointmentType.value"
                      :value="multipleAppointmentType.value"
                      text
                      small
                      :disabled="!event.isNew && event.evn_enm_multiple_appointment_type != multipleAppointmentType.value"
                    >
                      {{ multipleAppointmentType.text }}
                    </v-btn>
                  </v-btn-toggle>
                  <!--max client count per booking-->
                  <v-text-field
                    v-model="event.evn_int_max_client_count_per_booking"
                    v-if="event.evn_enm_multiple_appointment_type == 'client'"
                    label="Max antal personer per bokning"
                    outlined
                    dense
                    type="number"
                    min="1"
                    max="1000"
                    class="mt-2"
                  ></v-text-field>
                  <div class="text-subtitle-1 font-weight-medium pt-2 mt-4">Salonger</div>
                  <v-chip-group
                    v-model="event.saloons"
                    multiple
                    mandatory
                    active-class="primary--text"
                    item-value="id"
                    item-text="name"
                    column
                  >
                    <v-chip
                      v-for="saloon in selectableSaloons"
                      :key="saloon.id"
                      :value="saloon.id"
                    >
                      {{ saloon.name }}
                    </v-chip>
                  </v-chip-group>
                  <div class="text-caption red--text" v-if="selectableSaloons.length == 0">
                    Inga salonger matchar begränsningsvalet
                  </div>
                  <!--session type--->
                  <div class="text-subtitle-1 font-weight-medium py-2 mt-4">Bokningstyp</div>
                  <div>
                    <v-btn-toggle
                      v-model="event.evn_enm_session_type"
                      class="mb-3 xDenseToggle"
                      color="blue darken-4"
                      mandatory
                    >
                      <v-btn
                        v-for="sessionType in sessionTypes"
                        :key="sessionType.value"
                        :value="sessionType.value"
                        text
                        small
                        :disabled="sessionType.disabled"
                      >
                        {{ sessionType.text }}
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                  <!--slot settings-->
                  <template v-if="event.evn_enm_session_type == 'slot'">
                    <div class="text-subtitle-1 font-weight-medium py-2">Eventlängd</div>
                    <div>
                      <v-btn-toggle
                        v-model="event.evn_enm_duration_type"
                        class="mb-3 xDenseToggle"
                        color="blue darken-4"
                        mandatory
                      >
                        <v-btn
                          v-for="eventDurationType in eventDurationTypes"
                          :key="eventDurationType.value"
                          :value="eventDurationType.value"
                          text
                          small
                        :disabled="eventDurationType.disabled"
                        >
                          {{ eventDurationType.text }}
                        </v-btn>
                      </v-btn-toggle>
                      <div class="text-caption mb-4">
                        "Varierbar" innebär att kunden själv väljer hur lång session den vill boka genom att välja bland tider med start- och sluttid
                        <br>
                        "Fast" innebär att kunden väljer en starttid och sessionens längd blir det du anger nedan
                      </div>
                    </div>
                    <!--event duration-->
                    <v-menu
                      ref="eventDurationMenu"
                      v-model="eventDurationMenu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="event.evn_tim_duration"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      v-if="event.evn_enm_duration_type == 'static'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="event.evn_tim_duration"
                          label="Eventlängd"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="eventDurationMenu2"
                        v-model="event.evn_tim_duration"
                        full-width
                        format="24hr"
                        @click:minute="$refs.eventDurationMenu.save(event.evn_tim_duration)"
                        :allowed-minutes="allowedMinutes"
                      ></v-time-picker>
                    </v-menu>
                    <!--slots-->
                    <div class="text-subtitle-1 font-weight-medium py-2 mt-2">Allmäna tider</div>
                    <div class="text-caption mb-4" v-text="slotSyntaxExplanation"></div>
                    <!--saloon slots-->
                    <v-row class="mb-4">
                      <template v-for="saloon of saloons">
                        <v-col
                          :key="saloon.id"
                          v-if="event.saloons.indexOf(saloon.id) != -1"
                          cols="6"
                          sm="4"
                          md="3"
                          lg="2"
                          xl="2"
                        >
                          <v-textarea
                            v-model="saloon.slots"
                            :label="saloon.name"
                            outlined
                            dense
                            hide-details
                          ></v-textarea>
                        </v-col>
                      </template>
                    </v-row>
                    <!--date arrangement by type-->
                    <div class="text-subtitle-1 font-weight-medium py-2 mt-4">Definiera bokningsbara dagar utifrån</div>
                    <div>
                      <v-btn-toggle
                        v-model="event.evn_enm_date_arrangement_by_type"
                        class="mb-3 xDenseToggle"
                        color="blue darken-4"
                        mandatory
                      >
                        <v-btn
                          v-for="dateArrangementByType in dateArrangementByTypes"
                          :key="dateArrangementByType.value"
                          :value="dateArrangementByType.value"
                          text
                          small
                        :disabled="dateArrangementByType.disabled"
                        >
                          {{ dateArrangementByType.text }}
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                    <template v-if="event.evn_enm_date_arrangement_by_type == 'weekday'">
                      <div class="text-subtitle-2 font-weight-medium py-2 mt-2">Välj bokningsbara veckodagar</div>
                      <!---weekdays--->
                      <v-chip-group
                        mandatory
                        active-class="primary--text"
                        v-model="event.weekdays"
                        :show-arrows="false"
                        column
                        multiple
                      >
                        <v-chip
                          v-for="weekday in weekdays"
                          :key="weekday.value"
                          :value="weekday.value"
                        >
                          {{ weekday.text }}
                        </v-chip>
                      </v-chip-group>
                      <!--no red days-->
                      <v-switch
                        v-model="event.evn_bit_no_red_days"
                        label="Röda dagar ej bokningsbara"
                      ></v-switch>
                      <v-switch
                        v-model="event.hasAnyWeekdaySpecificSlots"
                        label="Definiera veckodagsspecifika tider"
                      ></v-switch>
                      <template v-if="event.hasAnyWeekdaySpecificSlots">
                        <div class="text-subtitle-1 font-weight-medium py-2 mt-2">Ev. veckodagsspecifika tider</div>
                        <div class="text-caption mb-4" v-text="slotSyntaxExplanation"></div>
                        <!--weekday saloon slots-->
                        <div v-for="weekday in weekdays" :key="weekday.value">
                          <template v-if="event.weekdays.indexOf(weekday.value) != -1">
                            <div class="text-subtitle-2 font-weight-medium pb-2">{{ weekday.text }}</div>
                            <v-row class="pa-0">
                              <template v-for="saloonSlot in weekday.saloonSlots">
                                <v-col
                                  :key="saloonSlot.id"
                                  v-if="event.saloons.indexOf(saloonSlot.id) != -1"
                                  cols="6"
                                  sm="4"
                                  md="3"
                                  lg="2"
                                  xl="2"
                                >
                                  <v-textarea
                                    v-model="saloonSlot.slots"
                                    :label="saloonSlot.name"
                                    outlined
                                    dense
                                  ></v-textarea>
                                </v-col>
                              </template>
                            </v-row>
                          </template>
                        </div>
                      </template>
                      <!--
                      <v-row>
                        <template v-for="saloonSlot of event.saloonSlots">
                          <v-col
                            :key="saloonSlot.id"
                            v-if="event.saloons.indexOf(saloonSlot.id) != -1"
                          >
                            <v-textarea
                              v-model="saloonSlot.slots"
                              :label="saloonSlot.name"
                              outlined
                              dense
                            ></v-textarea>
                          </v-col>
                        </template>
                      </v-row>
                      -->
                    </template>
                    <template v-else-if="event.evn_enm_date_arrangement_by_type == 'date'">
                      <!--dates-->
                      <v-data-table
                        :headers="event.datesHeaders"
                        :items="event.dates"
                        sort-by="date"
                        hide-default-footer
                        dense
                        disable-pagination
                        @click:row="editDate"
                        class="xClickRowTable mb-2"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            color="secondary"
                            small
                            icon
                            @click.stop="removeDate(item)"
                          >
                            <v-icon>
                              mdi-trash-can-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:[`item.saloonSlots`]="{ item }">
                          <template v-for="saloonSlot of item.saloonSlots">
                            <div
                              v-if="saloonSlot.slots.length"
                              :key="saloonSlot.id"
                            >
                              {{ saloonSlot.name }}: {{ saloonSlot.slots }} 
                            </div>
                          </template>
                        </template>
                      </v-data-table>
                      <div>
                        <v-menu
                          ref="dateSelection"
                          v-model="dateSelection"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="secondary"
                            >
                              Lägg till datum
                            </v-btn>
                          </template>
                          <v-date-picker
                            v-model="datesToAdd"
                            multiple
                            no-title
                            scrollable
                            :allowed-dates="allowedDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateSelection = false"
                            >
                              Avbryt
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="onDatePicker()"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </div>
                      <!--edit dates dialog-->
                      <v-dialog
                        v-model="editDateDialog"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                      >
                        <v-card v-if="editDateDialog">
                          <v-toolbar
                            dark
                            color="secondary"
                          >
                            <v-btn
                              icon
                              dark
                              @click="editDateDialog = false"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Redigera datum: {{ dateBeingEdited.date }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                              <v-btn
                                dark
                                text
                                @click="saveDate"
                              >
                                Spara
                              </v-btn>
                            </v-toolbar-items>
                          </v-toolbar>
                          <v-card-text>
                            <div class="text-subtitle-1 font-weight-medium py-2 mt-2">Datumspecifika tider</div>
                            <div class="text-caption mb-4" v-text="slotSyntaxExplanation"></div>
                            <!--saloon slots-->
                            <v-row>
                              <template v-for="saloonSlot in dateBeingEdited.saloonSlots">
                                <v-col
                                  :key="saloonSlot.id"
                                  v-if="event.saloons.indexOf(saloonSlot.id) != -1"
                                  cols="6"
                                  sm="4"
                                  md="3"
                                  lg="2"
                                  xl="2"
                                >
                                  <v-textarea
                                    v-model="saloonSlot.slots"
                                    :label="saloonSlot.name"
                                    outlined
                                    dense
                                  ></v-textarea>
                                </v-col>
                              </template>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </template>
                    <div class="text-subtitle-1 font-weight-medium py-2 mt-4">Begränsningar</div>
                    <div class="text-subtitle-2 py-2">Minsta tid mellan bokningstillfälle och bokad tid</div>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="event.evn_int_min_day_bookable_in_advance"
                          label="Dagar"
                          outlined
                          dense
                          type="number"
                          min="0"
                          max="365"
                          :rules="[formRules.required]"
                          validate-on-blur
                          class="mt-2"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="event.evn_int_min_hour_bookable_in_advance"
                          label="Timmar"
                          outlined
                          dense
                          type="number"
                          min="0"
                          max="23"
                          :rules="[formRules.required]"
                          validate-on-blur
                          class="mt-2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <template v-if="event.evn_enm_session_type == 'slot'">
                      <v-text-field
                        v-model="event.evn_int_max_booking_day_count"
                        label="Max antal dagar framåt i tiden som är bokningsbara"
                        outlined
                        dense
                        type="number"
                        min="1"
                        max="365"
                        class="mt-2"
                      ></v-text-field>
                    </template>
                    <!--multiple slot select-->
                    <v-switch
                      class="mt-1"
                      v-model="event.multipleSlotSelect"
                      label="Tillåt val av flera tider per bokning"
                    ></v-switch>
                    <div class="ml-12" v-if="event.multipleSlotSelect">
                      <!--max slot per booking-->
                      <v-text-field
                        v-model="event.evn_int_max_slot_per_booking"
                        label="Ev. max antal tider som kan bokas per bokning"
                        outlined
                        dense
                        type="number"
                        min="2"
                        max="255"
                        hide-details
                        class="mb-4"
                      ></v-text-field>
                    </div>
                    <!--when non-multiple slot select and multiple saloons-->
                    <!--allow customer selected saloon-->
                    <v-switch
                      v-if="event.saloons.length > 1 && !event.multipleSlotSelect"
                      class="mt-1"
                      v-model="event.evn_bit_saloon_selectable_by_client"
                      label="Tillåt kunden välja salong"
                    ></v-switch>
                    <!--allow unspecified saloon-->
                    <v-switch
                      v-if="event.saloons.length > 1 && !event.multipleSlotSelect && event.evn_bit_saloon_selectable_by_client"
                      class="mt-1"
                      v-model="event.evn_bit_allow_unspecified_saloon"
                      label="Tillåt kunden välja ospecificerad salong"
                    ></v-switch>
                  </template>
                </v-tab-item>
                <!--tab: price-->
                <v-tab-item
                  value="price"
                >
                  <!--free-->
                  <v-switch
                    v-model="event.free"
                    label="Kostnadsfritt"
                  ></v-switch>
                  <template v-if="!event.free">
                    <v-switch
                      v-model="event.payFirst"
                      label="Ta betalt vid bokning"
                    ></v-switch>
                    <div class="text-subtitle-1 font-weight-medium py-2">Ta betalt per</div>
                    <!--price per type-->
                    <v-btn-toggle
                      v-model="event.evn_enm_price_per_type"
                      class="xDenseToggle mb-4"
                      color="blue darken-4"
                      mandatory
                    >
                      <v-btn
                        v-for="pricePerType in pricePerTypes"
                        :key="pricePerType.value"
                        :value="pricePerType.value"
                        text
                        small
                        :disabled="pricePerType.disabled"
                      >
                        {{ pricePerType.text }}
                      </v-btn>
                    </v-btn-toggle>
                    <template v-if="event.multipleSlotSelect">
                      <v-switch
                        v-model="event.evn_bit_price_for_multiple_slots"
                        label="Ta bara betalt en gång per bokning, oavsett hur många tider som väljs"
                      ></v-switch>
                    </template>
                    <app-price-input :priceData="event.priceData" :pricePerType="event.evn_enm_price_per_type"></app-price-input>
                    <!--TODO: offer saloon specific prices
                    <template
                      v-for="saloon of saloons"
                    >
                      <div
                        v-if="event.saloons.indexOf(saloon.id) != -1"
                        :key="saloon.id"
                      >
                        <app-price-input :data="saloons.priceData"></app-price-input>
                      </div>
                    </template>
                    -->
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--loader-->
    <v-overlay
      :value="loading"
      opacity="0.8"
      z-index="1001"
    >
      <v-progress-circular
        :size="60"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
    <!--delete confirmation-->
    <v-bottom-sheet v-model="confirmDelete">
      <v-sheet
        class="text-center"
        height="160px"
      >
        <div class="py-4">
          Eventet kommer nu tas bort
        </div>
        <v-btn
          color="bad"
          dark
          @click="removeEvent()"
        >
          Ta bort
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </v-main>
</template>

<script>
import axios from 'axios'
import SnackBar from '../components/SnackBar'
import PriceInput from '../components/PriceInput'
import { snackBarBus } from '../main'
import { mapGetters } from 'vuex'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'
import { dateMixin } from '../mixins/date'
import { arrayMixin } from '../mixins/array'

export default {
  name: 'EventEdit',
  components: {
    'app-snack-bar': SnackBar,
    'app-price-input': PriceInput
  },
  mixins: [generalMixin, urlMixin, dateMixin, arrayMixin],
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    loading: false,
    viewDataIsLoaded: false,
    event: {
      evn_enm_session_type: null,
      evn_enm_multiple_appointment_type: null,
      evn_enm_duration_type: null,
      evn_tim_duration: null,
      evn_enm_date_arrangement_by_type: null,
      dates: [],
      slots: [],
      saloonSlots: [],
      hasAnyWeekdaySpecificSlots: null

    },
    eventDurationMenu2: false,
    dateSelection: false,
    datesToAdd: null,
    eventGroups: [],
    sessionTypes: [],
    multipleAppointmentTypes: [],
    eventDurationTypes: [],
    dateArrangementByTypes: [],
    weekdays: [],
    clientTypes: [],
    saloons: [],
    slotMenu2: false,
    slotTime: {
      start: null,
      end: null
    },
    editDateDialog: false,
    dateBeingEdited: null,
    confirmDelete: false,
    editDateSlots: [],
    tabMain: null,
    tabs: [
      {
        label: "general",
        name: "Allmänt"
      }
      ,{
        label: "occasion",
        name: "Tillfällen"
      }
      ,{
        label: "price",
        name: "Priser"
      }
    ]
  }),
  computed: {
    ...mapGetters(['agent']),
    selectableSaloons() {
      return this.saloons.filter(saloon => saloon.sln_enm_multiple_appointment_type == this.event.evn_enm_multiple_appointment_type)
    },
    slotSyntaxExplanation() {
      return this.event.evn_enm_duration_type === 'static' ? 'Ange enligt formatet T:MM (exempelvis 19:00). Vid flera tider, separera med radbrytning' : 'Ange enligt formatet T:MM-T:MM (exempelvis 8:30-10:00). Vid flera tider, separera med radbrytning'
    },
    pricePerTypes() {
      return [
        {
          value: 'booking',
          text: 'Bokning',
          disabled: false
        },
        {
          value: 'client',
          text: 'Besökare',
          disabled: this.event.evn_enm_multiple_appointment_type != 'client'
        }
      ]
    }
  },
  watch: {
    slotTime(newValue) {
      console.log('slotTime', newValue)
      if (newValue) {
        this.event.slots.push(newValue)
      }
    }
  },
  methods: {
    getData() {
      console.log('getData')
      if (this.loading) {
        return
      }
      this.loading = true
      let relUrl = '/admin/event-edit/' + this.agent + '/' + this.id
      const url = this.url_composeApiUrl(relUrl)
      axios.get(url)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onData(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
    onData(data) {
      this.event = data.event
      this.eventGroups = data.eventGroups
      this.sessionTypes = data.sessionTypes
      this.multipleAppointmentTypes = data.multipleAppointmentTypes
      this.eventDurationTypes = data.eventDurationTypes
      this.dateArrangementByTypes = data.dateArrangementByTypes
      this.weekdays = data.weekdays
      this.saloons = data.saloons
      this.clientTypes = data.clientTypes
      //final
      this.viewDataIsLoaded = true
    },
    onSave() {
      this.goHome()
    },
    goHome() {
      //go to home with event tab active, like this
      this.$router.replace({ name: 'home', hash: '#events' })
    },
    removeEvent() {
      this.confirmDelete = false
      const relUrl = '/admin/event/' + this.agent + '/' + this.id
      const url = this.url_composeApiUrl(relUrl)
      axios.delete(url)
        .then(response => {
          console.log('response', response)
          if (response.data.success) {
            //success
            this.onSave()
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
        })
    },
    saveEvent() {
      if (this.loading) {
        return
      }
      const formIsValid = this.$refs.formEdit.validate()
      if (!formIsValid) {
        snackBarBus.$emit('message', 'Formuläret är inte komplett ifyllt')
        return
      }
      if (this.event.saloons.length == 0) {
        snackBarBus.$emit('message', 'Minst en salong måste väljas')
        return
      }
      switch (this.event.evn_enm_date_arrangement_by_type) {
        case 'date':
          if (this.event.dates.length == 0) {
            snackBarBus.$emit('message', 'Minst ett datum måste läggas till')
            return
          }
        break
      }
      this.loading = true
      const postData = {
        agent: this.agent,
        event: this.event,
        saloons: this.saloons, // contains the general slots for the event in resp. saloon
        weekdays: this.weekdays // contains the weekday specifi slots for the event in resp. saloon
      }
      const config = {}
      let relUrl = '/admin/event'
      const url = this.url_composeApiUrl(relUrl)
      console.log('post', postData)
      axios.post(url, postData, config)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onSave()
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
    allowedDates: val => new Date(val).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0),
    onDatePicker() {
//      this.$refs.dateSelection.save(this.datesToAdd)
      //ensure datesToAdd in this.event.dates
      for (let date of this.datesToAdd) {
        if (!this.array_hasObjectByKeyValue(this.event.dates, 'date', date)) {
          //add date
          let data = {
            date: date,
            saloonSlots: []
          }
          //compose saloonSlots with empty slots for each available of event
          for (let saloon of this.saloons) {
            data.saloonSlots.push({
              id: saloon.id,
              name: saloon.name,
              slots: ''
            })
          }
          this.event.dates.push(data)
        }
      }
      this.dateSelection = false
      this.datesToAdd = []
    },
    editDate(date) {
      this.dateBeingEdited = date
      this.editDateDialog = true
    },
    saveDate() {
      this.dateBeingEdited = null
      this.editDateDialog = false
    },
    removeDate(date) {
      this.event.dates.splice(this.event.dates.indexOf(date), 1)
    }
  },
  created() {
    this.getData()
  }
}
</script>
